import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/james/MedGrocer/nn-pap/src/components/Layout/Layout.js";
import Container from "layout/Container";
import SEO from "layout/SEO";
import { Brand, ProgramName } from "elements/Brand";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Novo Nordisk Patient Consent Form" mdxType="SEO" />
      <div className="content is-medium">
        <h3> Novo Nordisk Patient Consent Form </h3>
        <p>{`I have been prescribed with `}<Brand mdxType="Brand" />{` by my doctor and been provided professional advice on the diagnosis and treatment of my diabetes. I would like, however, to know more about the medication and get sufficient support throughout my treatment journey. Therefore, upon reaching out to the MedGrocer Health Coach and confirmation of my enrollment, I confirmed that I am voluntarily joining the `}<ProgramName mdxType="ProgramName" />{` (“Program”) to help me manage my diabetes well. This Program and my participation in this Program were clearly and thoroughly explained to me by my doctor and I understand that joining this Program may help me to achieve better health outcomes.`}</p>
        <p>{`I understand that:`}</p>
        <ol>
          <li parentName="ol">{`MG Health Solutions Inc. (“MedGrocer”) is the authorized representative of Novo Nordisk Pharmaceutical (Philippines), Inc. (“Novo Nordisk Philippines”) in the implementation of the Program.`}</li>
          <li parentName="ol">{`MedGrocer’s Health Coach will provide more information on the mechanics and current benefits of the Program such as education, access, governmental assistance and safety support throughout my stay with the Program.`}</li>
          <li parentName="ol">{`The Health Coach may collect and process certain personal information for my enjoyment of the benefits of the Program and know that any information relayed about my Medical Condition, the importance of therapy, method of administration, precautions and special instructions is for informational purposes only and not meant to replace the professional advice of my doctor.`}</li>
          <li parentName="ol">{`All personal information obtained will be handled with full and strict confidentiality in compliance with the Republic Act No. 10173 or the “Data Privacy Act of 2012” and that I have the right to access, correct, update and object to the processing of my personal information at any time by submitting a written request to the Data Privacy Officer of MedGrocer and in appropriate cases, to lodge a complaint before the National Privacy Commission.`}</li>
          <li parentName="ol">{`The Health Coach will call and send me text messages to provide reminders, support for my Medical Condition and updates on the Program and I can call the Health Coach to clarify any concerns about the Program and to correct any personal information I have given.`}</li>
          <li parentName="ol">{`I will comply with all guidelines of the Program to enjoy its benefits; otherwise, my enrolment in the Program may be terminated.`}</li>
          <li parentName="ol">{`Novo Nordisk Philippines reserves the right to terminate any of the benefits available under the Programme or the Program itself at any time without prior notice.`}</li>
          <li parentName="ol">{`Novo Nordisk Philippines and MedGrocer are obliged to collect details of any adverse events or product quality complaints that I may experience with `}<Brand mdxType="Brand" />{` during the conduct of the Program and throughout my medication.
Should an adverse event or product complaint be identified, I agree that Novo Nordisk Philippines and/or MedGrocer will collect this information from either me and/or my doctor for safety reporting purposes only.
`}</li>
        </ol>
        <p>{`I hereby represent and warrant that all personal information that I have provided for/will provide in connection with my application for this Program is correct, accurate, and true. I confirm that my participation in this Program is voluntary and I am free to withdraw at any time based on my own decision or as recommended by my doctor and as such I will inform MedGrocer of my withdrawal by emailing `}<a href="mailto:ozempic@medgrocer.com" className="has-text-weight-bold">{`ozempic@medgrocer.com`}</a>{`.`}</p>
        <h3> Waiver </h3>
        <p>{`I will indemnify and hold Novo Nordisk Philippines, MedGrocer, and their partners free from any liabilities that may arise as a consequence of my decision to join the Program. I also acknowledge that Novo Nordisk Philippines or MedGrocer shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of:`}</p>
        <ol>
          <li parentName="ol">
            <p parentName="li">{`Any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Any damage to or loss of or inability to retrieve any data that may be stored in the MedGrocer database.`}</p>
          </li>
        </ol>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      